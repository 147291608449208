import * as React from 'react';

import { _, link, linkSmall } from '@src/translations/utils.translations';

export const ERROR_TRANSLATIONS = {
  error_unauthenticated: _(
    () => (
      <>
        Эта страница вам не доступна. {linkSmall('/login', 'Войти на сайт')} или{' '}
        {linkSmall('/', 'перейти на главную')}.
      </>
    ),
    () => (
      <>
        This page is not accessible to you. {linkSmall('/login', 'Login to the site')} or{' '}
        {linkSmall('/', 'go to the homepage')}.
      </>
    ),
  ),
  Unauthorized: _('Нет доступа', 'Unauthorized'),
  errors: {
    INVALID_USERNAME_OR_PASSWORD: _(
      'Неверное имя пользователя или пароль',
      'Invalid username or password',
    ),
    BOX_ARCHIVED: _(
      'Коробка находится в архиве, невозможно изменить',
      'The box is archived, cannot be modified',
    ),
    BOX_KEY_ALREADY_EXIST: _(
      'Такой идентификатор уже существует',
      'Such an identifier already exists',
    ),

    USER_NOT_FOUND: _(
      () => <>Мы не нашли пользователя с таким email. {link('/register', 'Зарегистрироваться?')}</>,
      () => <>We could not find a user with that email. {link('/register', 'Register?')}</>,
    ),
    PASSWORD_HAS_BEEN_ALREADY_RESET: _(
      <>
        Похоже, вы уже меняли пароль по этой ссылке. {linkSmall('/login', 'Войдите')} на сайт или{' '}
        {linkSmall('/recover', 'сбросьте пароль')} еще раз.
      </>,
      <>
        It seems you have already changed your password using this link.{' '}
        {linkSmall('/login', 'Log in')} to the site or{' '}
        {linkSmall('/recover', 'reset your password')} again.
      </>,
    ),
    PASSWORD_RESET_WRONG_PARAMETERS: _(
      'Что-то пошло не так. Вы уверены, что перешли по верной ссылке для сброса пароля? Давайте попробуем еще раз.',
      "Something went wrong. Are you sure you followed the correct link for password reset? Let's try again.",
    ),
    UNAUTHORIZED_FOR_BOX: _(
      <>
        Доступ к коробке возможен только зарегистрированным или приглашенным участникам. Войдите на
        сайт или перейдите по пригласительной ссылке, чтобы создать карточку.
        <br />
        <br />
        Пригласительная ссылка выглядит примерно так:
        <br />
        <b>https://santa-secret.ru/box/example/card?join=J0InS3cre7S4n74</b>
      </>,
      <>
        Access to the box is only possible for registered or invited participants. Log in to the
        site or follow the invitation link to create a card.
        <br />
        <br />
        An invitation link looks like this:
        <br />
        <b>https://santa-secret.ru/box/example/card?join=J0InS3cre7S4n74</b>
      </>,
    ),
    INVALID_BOX_KEY: _(
      "Некорректный идентификатор. Можно использовать только латинские буквы, цифры и символы '_', '-'. Длина должна быть менее 64 символов.",
      "Invalid identifier. Only Latin letters, numbers, and the symbols '_', '-' can be used. The length must be less than 64 characters.",
    ),
    CLIENT_DRAW_ERROR: _(
      'Невозможно провести жеребьбевку. Вероятно вы пометили слишком много карточек одним цветом или указали вручную слишком много подопечных',
      'Unable to conduct the draw. You probably marked too many cards with the same color or assigned too many giftees manually.',
    ),
    FORM_HAS_ERRORS: _('В некоторых полях допущены ошибки', 'There are errors in some fields'),
    MIN_2_REQUIRED: _('Необходимо хотя бы два участника', 'At least two participants required'),
    MIN_3_REQUIRED: _('Необходимо хотя бы три участника', 'At least three participants required'),
    RANDOMIZER_RESULTS_NOT_FOUND: _('Результаты жеребьевки не найдены', 'Draw results not found'),
    UNKNOWN: _('Неизвестная ошибка', 'Unknown error'),
    SAME_CARD_USERNAME_EXISTS: _(
      (username) =>
        `Участник с таким же именем уже добавлен в коробку. Измените имя, добавив фамилию или ник`,
      (username) =>
        'A participant with the same name has already been added to the box. Change the name by adding a last name or nickname',
    ),

    SAME_CARD_EMAIL_EXISTS: _(
      (email) => `Участник с таким же email уже добавлен в коробку.`,
      (username) => 'A participant with the same email has already been added to the box.',
    ),
    'Internal Server Error': _('Внутренняя ошибка сервера', 'Internal Server Error'),
    USER_ALREADY_EXISTS: _(
      (email: string) => (
        <>Такой пользователь уже зарегистрирован. {link('/login', 'Войти?', () => {})}</>
      ),
      (email: string) => <>Same user is already registered. {link('/login', 'Login?', () => {})}</>,
    ),
    '"email" must be a valid email': _(
      'Похоже, в email допущена ошибка?',
      "It seems there's a mistake in the email?",
    ),
    INVALID_PASSWORD: _(
      () => <>Неверный пароль. {link('/recover', 'Восстановить пароль?', () => {})}</>,
      () => <>Incorrect password. {link('/recover', 'Recover password?', () => {})}</>,
    ),
    RANDOMIZER_FAILED: _(
      'Невозможно провести жеребьевку. Скорее всего, вы пометили слишком много карточек одним цветом или указали вручную слишком много подопечных.',
      'Unable to conduct the draw. You probably marked too many cards with the same color or manually assigned too many giftees.',
    ),
    CANNOT_DELETE_CARD_AFTER_DRAW_ASK_ADMIN: _(
      'Вы не можете сами удалить карточку после жеребьевки. Обратитесь к организатору коробки.',
      'You cannot delete your card after the draw. Contact the box organizer.',
    ),
    CANNOT_DELETE_CARD_AFTER_DRAW_TOO_FEW_USERS: _(
      'Невозможно удалить карточку, уже слишком мало участников.',
      'Unable to delete the card, there are too few participants left.',
    ),
    CANNOT_DELETE_ACCOUNT_USER_HAS_ACTIVE_BOXES: _(
      'Невозможно удалить аккаунт, так как есть созданные вами коробки. Удалите или заархивируйте коробки и попробуйте снова.',
      'Cannot delete account as you have created boxes. Delete or archive the boxes and try again.',
    ),
    CANNOT_DELETE_ACCOUNT_USER_IN_ACTIVE_GAMES: _(
      'Невозможно удалить аккаунт, так как вы состоите в активных розыгрышах. Удалите свои карточки из активных игр и попробуйте снова.',
      'Cannot delete account as you are in active draws. Remove your cards from active games and try again.',
    ),
    CARD_POSTAL_ADDRESS_REQUIRED: _('Почтовый адрес обязателен', 'Postal address is required'),
    CARD_POSTAL_CODE_REQUIRED: _('Почтовый индекс обязателен', 'Postal code is required'),
    CARD_POSTAL_RECIPIENT_REQUIRED: _('Получатель обязателен', 'Recipient is required'),
    CARD_PHONE_REQUIRED: _('Телефон обязателен', 'Phone number is required'),
    CARD_EMAIL_REQUIRED: _('Email обязателен', 'Email is required'),
    invalid_email_or_password: _(
      () => 'Неверная комбинация email и пароля',
      () => 'Invalid email or password',
    ),
    user_already_registered: _(
      () => 'Такой пользователь уже зарегистрирован',
      () => 'Same user is already registered',
    ),
    password_required: _(() => 'Введите пароль', 'Password required'),
    already_reset_password: _(
      <>
        Похоже, вы уже недавно меняли пароль по этой ссылке. {linkSmall('/login', 'Войдите')} на
        сайт или {linkSmall('/recover', 'сбросьте пароль')} еще раз.
      </>,
      <>
        It seems you have already recently changed your password using this link.{' '}
        {linkSmall('/login', 'Log in')} to the site or{' '}
        {linkSmall('/recover', 'reset your password')} again.
      </>,
    ),
    something_wrong_while_resetting: _(
      <>
        Что-то пошло не так. Вы уверены, что перешли по верной ссылке для сброса пароля? Давайте{' '}
        {linkSmall('/recover', 'попробуем еще раз')}.
      </>,
      <>
        Something went wrong. Are you sure you followed the correct link for password reset? Let's{' '}
        {linkSmall('/recover', 'try again')}.
      </>,
    ),
    same_box_key_already_exist: _(
      'Такой идентификатор уже существует',
      'Same identifier already exists',
    ),
    randomizer_wrong_uuid: _(
      'Результаты жеребьевки не найдены. Вы уверены что перешли по верной ссылке?',
      'Draw results not found. Are you sure you followed the correct link?',
    ),
    SAME_EMAIL_ACCOUNT_EXISTS: _(
      <>
        Активный пользователь с таким адресом уже существует. Если это ваш аккаунт, то{' '}
        {linkSmall('/login', 'войдите на сайт')} с этим адресом и удалите аккаунт. После этого вы
        сможете привязать почту к текущему пользователю.
      </>,
      <>
        An active user with this address already exists. If it's your account, then{' '}
        {linkSmall('/login', 'log in to the site')} with this address and delete the account. After
        that, you will be able to link the email to the current user.
      </>,
    ),
    LINK_FAILURE_SAME_EMAIL_EXISTS: _(
      <>
        Активный профиль с таким социальным аккаунтом уже существует. Чтобы привязять его к текущему
        пользователю, {linkSmall('/login', 'войдите на сайт')} под ним и удалите профиль. После
        этого вы сможете привязать социальный аккаунт к текущему пользователю.
      </>,
      <>
        An active profile with this social account already exists. To link it to the current user,{' '}
        {linkSmall('/login', 'log in to the site')} under it and delete the profile. After that, you
        will be able to link the social account to the current user.
      </>,
    ),
    CANNOT_CHANGE_EMAIL_WITH_ORGANIZER_EMAIL: _(
      <>Вы не можете указать свой адрес для чужой карточки!</>,
      <>You cannot use your email for other card!</>,
    ),
    CASH_LIMIT_WRONG: _(<>Некорректное органичение стоимости</>, <>Incorrect cash limit</>),
  },
};
