import * as React from 'react';
import { BasePageProps } from '@src/pages/withBasePage';
import { Expandable, ExpandableGroup, InfoPanel, RowStart, SantaLink } from '@lub0v/ui-components';
import { t, TRANSLATIONS } from '@src/translations';
import { Lang } from '@src/types/lang';
import { ButtonSecondary, TextH3Semi } from '@src/components';

function FAQHomeComponent(props: BasePageProps & any) {
  const questions = TRANSLATIONS.home_questions;
  return (
    <div>
      <section style={{ paddingTop: '3rem', paddingBottom: '1rem', textAlign: 'left' }}>
        <div>
          <RowStart style={{ marginBottom: '3rem' }}>
            <TextH3Semi translator={props.translator}>home_questions_title</TextH3Semi>
          </RowStart>
          <ExpandableGroup>
            {questions.map((q) => (
              <Expandable key={q.question.translations[Lang.RU]} title={t(q.question)}>
                {t(q.answer)}
              </Expandable>
            ))}
          </ExpandableGroup>
        </div>
        <InfoPanel
          translator={props.translator}
          title={'home_have_more_questions'}
          message={'home_have_more_questions_hint'}
          style={{ marginTop: '5rem' }}
        >
          <SantaLink to="/faq">
            <ButtonSecondary translator={props.translator}>
              home_have_more_questions_button
            </ButtonSecondary>
          </SantaLink>
        </InfoPanel>
      </section>
    </div>
  );
}

export const FAQHome = FAQHomeComponent;
