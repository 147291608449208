import * as React from 'react';
import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { mapStateToPropsBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, SantaAppState } from '@src/state';
import { FormCard, GridColumn, GridContainer, Input, Select, SwitchPanel } from '@src/components';
import { NewBoxProps } from '@src/pages/new_box/NewBoxProps';
import { t, TRANSLATIONS } from '@src/translations';
import { getCurrencies } from '@src/types/currency';
import { santaClient } from '@src/state/client';
import { validateField } from '@src/utils/validators';
import { AdLookIBV } from '@src/components/AdLookIBV';

export function mapStateToProps(state: SantaAppState) {
  return {
    ...mapStateToPropsBasePage(state),
    boxCreate: state.boxCreate,
  };
}

export const NewBoxStepCost = connect(mapStateToProps)((props: NewBoxProps) => {
  const [error, setError] = useState(undefined);
  const currencies = getCurrencies();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onSubmit = async () => {
    const res = await santaClient.createBox(props.boxCreate.input);
    if (res.ok) {
      props.goNext();
    } else {
      setError(res.error.message);
    }
  };

  return (
    <FormCard
      title="new_box_step_cost_title"
      translator={props.translator}
      step={t(TRANSLATIONS.new_box_step_title, { step: props.boxCreate.step })}
      onSubmit={onSubmit}
      onBack={props.goBack}
      error={error}
    >
      <SwitchPanel
        title="new_box_step_cost_switch_title"
        hint="new_box_step_cost_switch_hint"
        value={props.boxCreate.input.useCashLimit}
        onChange={(useCashLimit) => {
          dispatchActionAppUpdateState({ boxCreate: { input: { useCashLimit } } });
        }}
      />
      {props.boxCreate.input.useCashLimit ? (
        <GridContainer spacing={2}>
          <GridColumn xs={3}>
            <Input
              label="new_box_step_cash_limit_min"
              value={props.boxCreate.input.cashLimitMin}
              max={6}
              number
              validators={[validateField(props.boxCreate.input, 'cashLimitMin', 'useCashLimit')]}
              onChange={(cashLimitMin) => {
                dispatchActionAppUpdateState({
                  boxCreate: { input: { cashLimitMin: +cashLimitMin } },
                });
              }}
            />
          </GridColumn>
          <GridColumn xs={3}>
            <Input
              label="new_box_step_cash_limit_max"
              value={props.boxCreate.input.cashLimit}
              max={6}
              number
              validators={[validateField(props.boxCreate.input, 'cashLimit', 'useCashLimit')]}
              onChange={(cashLimit) => {
                dispatchActionAppUpdateState({ boxCreate: { input: { cashLimit: +cashLimit } } });
              }}
            />
          </GridColumn>
          <GridColumn xs={6}>
            <Select
              label="currency_title"
              options={currencies}
              validators={[
                validateField(props.boxCreate.input, 'cashLimitCurrency', 'useCashLimit'),
              ]}
              onChange={(cashLimitCurrency) => {
                dispatchActionAppUpdateState({ boxCreate: { input: { cashLimitCurrency } } });
              }}
            />
          </GridColumn>
        </GridContainer>
      ) : null}
      <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
    </FormCard>
  );
});
