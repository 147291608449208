import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { BasePageProps, mapStateToPropsBasePage, withBasePage } from '@src/pages/withBasePage';
import { dispatchActionAppUpdateState, dispatchActionModal, SantaAppState } from '@src/state';
import { FeedbackInput } from '@lub0v/santa-utils';
import {
  ButtonDark,
  ColumnCenter,
  ErrorBoundary,
  Expandable,
  ExpandableGroup,
  FormCard,
  Input,
  PandaWithGift,
  PictureNotice,
  RabbitWithCup,
  TextArea,
  Tip,
} from '@src/components';
import { validateField } from '@src/utils/validators';
import { santaClient } from '@src/state/client';
import { t, TRANSLATIONS } from '@src/translations';
import { Lang } from '@src/types/lang';
import { AdLookIBV } from '@src/components/AdLookIBV';

interface FeedbackPageProps extends BasePageProps {
  feedback: FeedbackInput;
}

function filterQuestion(userQuery: string, question: string, search: string) {
  const keyWords = search.split(' ');
  const searchWords = [userQuery]; //.split(' ');
  for (const w of searchWords) {
    if (search.toLowerCase().includes(w.toLowerCase())) {
      return true;
    }
  }
  for (const w of searchWords) {
    if (question.toLowerCase().includes(w.toLowerCase())) {
      return true;
    }
  }
  for (const keyWord of keyWords) {
    console.log(
      'KEY WORD ',
      keyWord,
      '  ',
      userQuery.toLowerCase(),
      ' ',
      userQuery.toLowerCase().includes(keyWord.toLowerCase()),
    );
    if (userQuery.toLowerCase().includes(keyWord.toLowerCase())) {
      return true;
    }
  }
  return false;
}

function FeedbackPageComponent(props: FeedbackPageProps) {
  const [confirmModal, setConfirmModal] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(undefined);
  const [stillSend, setStillSend] = useState(false);
  const send = useRef(null);
  const sendFeedback = async () => {
    if (sent) {
      props.history.goBack();
      return;
    }
    const res = await santaClient.feedback(props.feedback);
    if (res.ok) {
      dispatchActionAppUpdateState({ feedback: { message: '' } });
      setError(undefined);
      setSent(true);
    } else {
      setError(res.error.message);
    }
  };

  useEffect(() => {
    setStillSend(send.current);
  }, [send.current]);

  const setDidNotFindTheAnswer = async (confirm: boolean) => {
    setConfirmModal(confirm);
    dispatchActionModal({
      opened: confirm,
      title: 'modal_confirm_read_faq_title',
      message: 'modal_confirm_read_faq_message',
      buttonYes: 'modal_confirm_read_faq_button_send',
      buttonNo: 'modal_confirm_read_faq_button_no',
      loading: false,
      onCancel: () => {
        send.current = false;
        setDidNotFindTheAnswer(false);
      },
      onSubmit: async () => {
        send.current = true;
        setDidNotFindTheAnswer(false);
      },
    });
  };

  if (stillSend || send.current) {
    const allQuestions = TRANSLATIONS.questions.map((q) => q.questions);
    const all = [];
    for (const qsts of allQuestions) {
      for (const q of qsts) {
        const exists = all.find((_q) => _q.question === q.question);
        if (!exists) {
          all.push(q);
        }
      }
    }
    const translatedQuestions = all.map((q) => ({
      id: q.question.translations[Lang.RU],
      search: q.search,
      question: t(q.question),
      answer: t(q.answer),
    }));
    const questions =
      props.feedback.message && props.feedback.message.length
        ? translatedQuestions.filter((q) =>
            filterQuestion(props.feedback.message, q.question, q.search),
          )
        : [];
    const questionsRender = questions.map((q) => (
      <Expandable key={q.id} title={q.question}>
        {q.answer}
      </Expandable>
    ));
    return (
      <FormCard
        translator={props.translator}
        title="feedback_title"
        onBack={() => {
          props.history.goBack();
        }}
        btnLeft={
          sent ? null : (
            <ButtonDark
              translator={props.translator}
              onClick={() => {
                props.history.goBack();
              }}
            >
              cancel
            </ButtonDark>
          )
        }
        btnRightTitle={sent ? 'back_to_home' : 'feedback_send'}
        onSubmit={sendFeedback}
        error={error}
      >
        {sent ? (
          <ColumnCenter>
            <ErrorBoundary>
              <PictureNotice
                translator={props.translator}
                title="feedback_sent_title"
                hint="feedback_sent_hint"
              >
                <RabbitWithCup style={{ width: '14rem' }} />
              </PictureNotice>
            </ErrorBoundary>
            <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
          </ColumnCenter>
        ) : (
          <>
            <Tip blueberry icon="info">
              feedback_tip
            </Tip>
            <Input
              label="your_email"
              value={props.feedback.email}
              onChange={(email) => dispatchActionAppUpdateState({ feedback: { email } })}
              validators={[validateField(props.feedback, 'email')]}
            />
            <TextArea
              label="feedback_message_title"
              value={props.feedback.message}
              onChange={(message) => dispatchActionAppUpdateState({ feedback: { message } })}
              validators={[validateField(props.feedback, 'message')]}
            />
            <ExpandableGroup>{questionsRender}</ExpandableGroup>
            <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
          </>
        )}
      </FormCard>
    );
  }

  return (
    <FormCard
      translator={props.translator}
      title="feedback_please_read_faq_title"
      onBack={() => {
        props.history.goBack();
      }}
      btnLeft={
        <ButtonDark
          translator={props.translator}
          onClick={() => {
            props.history.goBack();
          }}
        >
          cancel
        </ButtonDark>
      }
      btnRightTitle="feedback_still_send"
      onSubmit={() => {
        // setStillSendMessage(true);
        setDidNotFindTheAnswer(true);
      }}
    >
      <ColumnCenter>
        <PictureNotice
          translator={props.translator}
          title=""
          hint="feedback_please_read_faq_hint"
          button2="feedback_go_to_faq"
          onClick2={() => {
            props.history.push('/faq');
          }}
        >
          <PandaWithGift />
        </PictureNotice>
        <AdLookIBV numAdsDesktop={1} numAdsMobile={1} />
      </ColumnCenter>
    </FormCard>
  );
}

export function mapStateToProps(state: SantaAppState) {
  return {
    feedback: state.feedback,
    ...mapStateToPropsBasePage(state),
  };
}

export const FeedbackPage = connect(mapStateToProps)(
  withBasePage<BasePageProps>(FeedbackPageComponent, { noFooter: true, wide: true }),
);
